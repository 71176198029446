<template>
	<img class="pageHeaderIcon" src="@/assets/bell.svg"/>

	<div v-if="!customer" class="middle-page-topthird">
		<v-progress-circular indeterminate></v-progress-circular>
	</div>

	<div v-if="customer">
		<p>Select which notifications you would like to receive. Notifications are sent at around 9am.</p>
		<!-- <p>Hi {{ customer.person.firstName }} {{ customer.person.lastName }}.  You are logged in to Money Guided. Authentication and data retrival is working!</p> -->
		<p>
			<v-switch class="switch" @change="savePrefs" hide-details="auto" v-model="customer.preferences.dailyCurrentBalanceNotifications" label="Send daily current balance notifications"></v-switch>
			<v-switch class="switch" @change="savePrefs" hide-details="auto" v-model="customer.preferences.dailyCreditBalanceNotifications" label="Send daily credit balance notifications"></v-switch>
		</p>
		<p>The above notifications will be sent using push notifications and you must enable these for the Money Guided app.</p>
		<br/>
		<!-- <div v-if="$route.meta.whatsapp">
			<hr/>
			<p class="mt-5">We can also communicate with you using WhatsApp messenger.  To enable 2 way chat with us, you need to enable WhatsApp messaging.</p>
			<p><v-btn class="mg-button" style="background-color: #1dcd45 !important;" @click="enableWhatsApp" :disabled="whatsappPressed">Enable WhatsApp Messaging</v-btn></p>
		</div> -->
	</div>
</template>

<script>
import Services from '../mixins/Services';
export default {
	mixins: [Services],
	data() {
		return {
			whatsappPressed: false
		}
	},
	methods: {
		// enableWhatsApp: function(){
		// 	var request = {
		// 		"title": "welcome_to_mg",
		// 		"whatsApp": true,
		// 		"whatsAppTemplate": true,
		// 		"phoneNumber": this.customer.person.mobilePhone
		// 	};
		// 	this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Notifications", request)
		// 		.then(() => {
		// 			this.whatsappPressed = true;
		// 		}
		// 	)
		// },  
		getCustomer: function(){
			this._getCustomer(() => {
				if (this.customer.preferences == null) {
					this.customer.preferences = { dailyCurrentBalanceNotifications: false, dailyCreditBalanceNotifications: false };
				}
			});
		},
		savePrefs: function(){
			this._savePrefrences(() => {
				if (this.customer.preferences == null) {
					this.customer.preferences = { dailyCurrentBalanceNotifications: false, dailyCreditBalanceNotifications: false };
				}
			});
		},
		onLoad: async function() {
			this.getCustomer();
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

</style>
