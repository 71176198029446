<template>

	<div class="card" style="background-color: #02549F">
		<v-row class="nameRow">
			<v-col class="v-col-9 nameCol">
				<p><b>{{voucher.discount.merchant.name}}</b></p>
				<span>{{formattedCategory}}</span>
			</v-col>
			<v-col class="right"><img class="bankLogo" :src="voucher.discount.merchant.logoUrl"/></v-col>
		</v-row>
		<v-row class="mt-0">
			<v-col class="balanceCol">{{ formatter.format(voucher.currentBalance)}}</v-col>
		</v-row>
		<v-row class="mt-1 mb-4">
			<v-col class="labelCol">Balance</v-col>
		</v-row>
		<v-row class="mt-5">
			<v-col class="v-col-6 tp-4">Expires: <b>{{ formattedExpiryDate }}</b></v-col>
			<v-col class="right no-top-padding"><v-chip size="small" v-if="voucher.discount.online" background-color="blue">Online</v-chip> <v-chip v-if="voucher.discount.inStore" size="small">In Store</v-chip></v-col>
		</v-row>
	</div>
</template>

<script>

export default {
	name: "VoucherCard",
	props: ['voucher', 'formatter'],
	computed: {
		formattedCategory() {
            const category = this.voucher.discount.merchant.categories.split(',')[0];
            return category.split('-').map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }).join(' ');
        },
        formattedExpiryDate() {
            const date = new Date(this.voucher.expiryDateTime);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}.${month}.${year}`;
        }
	},
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.card {
	color:var(--light-text-color); 
	padding: 25px; 
	height: 20vh; 
	min-height: 200px;
	border-radius: 10px;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.263);
}

.nameCol
{
	p{
		font-size: 15px;
		margin-bottom: 0px;
	};
	span{
		font-size: 12px;
	}
}

.balanceCol
{
	font-size: 28px;
	padding-bottom: 0px;
}

.labelCol
{
	padding-bottom: 0px;
	padding-top:0px;
	font-size: 12px;
}

.bankLogo
{
	width: 40px;
	border-radius: 50%;
}

</style>