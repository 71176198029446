<template>
	<a :href="href != null ? href : $router.path" :style="empty ? 'opacity: 0.7' : ''">
		<div class="account-add-button" v-if="isAddButton">
			<v-row>
				<v-col class="plusCol">
					<img class="plusIcon" src="../../assets/plus.png" />
				</v-col>
			</v-row>
		</div>
		<div class="account-summary-button" v-if="!isAddButton">
			<v-row>
				<v-col class="title-col">
					<span class="nowrap bold">{{ title }}</span>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<img class="icon" :src="icon" :style="circleIcon ? null : 'border-radius: 5px;'" />
				</v-col>
			</v-row>
			<v-row class="mt-0">
				<v-col>
					<span class="providerLabel nowrap">{{ provider }}</span>
				</v-col>
			</v-row>
			<v-row class="mt-0">
				<v-col class="v-col-7 balanceCol">
					<span class="balanceLabel nowrap">{{ balance }}</span>
				</v-col>
				<v-col class="arrow-col">
					<img class="arrow" src="../../assets/arrow-right.png">
				</v-col>
			</v-row>
		</div>
	</a>
</template>

<script>
export default {
	name: "AddButton",
	props: ['title', 'icon', 'provider', 'balance', 'circleIcon', 'isAddButton', 'href', 'empty'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.account-summary-button, .account-add-button {
	border-radius: 8px;
	padding: 18px;
	border-color: var(--border-color);
	border: 0px solid var(--border-color);
	height: 100%;
	background-color: var(--background-color);
	width: 100%;
	box-shadow: 0px 4px 8px 0px rgba(51, 40, 71, 0.05);
}

.account-add-button {
	background: #F0EEEE;
}

.plusCol {
	text-align: center;
	padding-top: 65px;
	padding-bottom: 64px;
}

.plusIcon {
	width: 30px;
}

.title-col {
	font-size: 13px;
	span {
		text-wrap: nowrap;
		overflow: ellipsis;
		display: block;
	}
}

.icon {
	width: 40px;
	h--eight: 40px;
	background: linear-gradient(230deg, #E7ECF7 16.24%, #EAEDFF 84.93%);
	border-radius: 50%;
}

.arrow-col {
	text-align: right;
	padding-left: 0px;
}

.arrow {
	width: 18px;
}

.providerLabel {

}

.balanceCol {
	padding-right: 0px;
}

.balanceLabel {

}


</style>