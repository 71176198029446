<template>
	
		<div class="article-list" :style="'background-image:url(' + bottomrighticon + ')'" @click="event => {event.stopPropagation()}">
			<div v-for="article in articles" :key="article.id" class="article-wrapper">
				<v-row>
					<v-col class="v-col-9 title-col">
						{{ article.title }}
					</v-col>
					<v-col class="v-col-3 thumb-col">
						<img :src="article.iconImage">
					</v-col>
				</v-row>
				<v-row>
					<v-col class="description-col">{{ article.shortSummary }}</v-col>
				</v-row>
				<v-row>
					<v-col class="v-col-9 tags-col">
						<v-chip variant="outlined" size="x-small">{{ article.tagsArray[0] }}</v-chip>
					</v-col>
					<v-col class="">
						
					</v-col>
					<v-col class="arrow-col">
						<a :href="'/#/article/' + article.id"><img class="arrow" src="../../assets/arrow-right.png"></a>
					</v-col>
				</v-row>
			</div>
		</div>
	
</template>

<script>
export default {
	name: "ArticleList",
	props: ['articles'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
			
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.article-wrapper {
	margin-top: 5px;
	padding-top: 20px;
	padding-bottom: 20px; 
	border-top: 1px solid var(--border-color)
}

.title-col {
	font-size: 15px;
}

.thumb-col {
	img {
		height: 40px;
		border-radius: 5px;
	}
	text-align: right;
}

.description-col {
	padding-top: 0px;
	font-weight: lighter;
}

.arrow-col {
	text-align: right;
	padding-left: 0px;
}

.arrow {
	width: 18px;
}

</style>