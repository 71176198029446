<template>
	<a :href="href != null ? href : $router.path">
		<div class="count-block">
			<div class="title">{{ title }}</div>
			<div class="value">{{ Intl.NumberFormat('en-GB').format(value) }} <span v-if="subvalue" class="subvalue">{{ subvalue }}</span></div>
		</div>
	</a>
</template>

<script>
export default {
	name: "CountBlock",
	props: ['title', 'value', 'subvalue', 'href'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>
.count-block {
	border-radius: 8px;
	background-color: var(--background-color);
	border: 1px solid var(--border-color);
	padding: 12px;
	height: 100%;
	padding-bottom: 17px;
}

.title {
	font-size: 12px;
	height: 60%;
}

.value {
	font-size: 20px;
}

.subvalue {
	font-size: 12px;
}

.title, .value {
	text-align:left;
}
</style>