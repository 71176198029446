<template>
	
	<div @click="event => {event.stopPropagation()}">
		<div class="transactions">
			<v-divider class="mt-2"></v-divider>
			<div v-for="g in voucher.customerVoucherTransactions" :key="g">
				<v-row class="mt-1 mb-1">
					<v-col v-if="g.createdDateTimeString != today && g.createdDateTimeString != yesterday" class="date-col">{{ formattedTransactionDate(g.createdDateTime) }}</v-col>
					<v-col v-if="g.createdDateTimeString == today" class="date-col">Today</v-col>
					<v-col v-if="g.createdDateTimeString == yesterday" class="date-col">Yesterday</v-col>
					<!-- <v-col class="right">{{ formatter.format(Math.round(g.amount))}}</v-col> -->
					<v-col class="amount-col">
						<span>{{ formatter.format(g.amount) }}</span>
					</v-col>
				</v-row>
				<v-divider class="mt-2"></v-divider>
			</div>
			<v-row class="mt-1 mb-1">
				<v-col v-if="voucher.createdDateTimeString != today && voucher.createdDateTimeString != yesterday" class="date-col">{{ formattedTransactionDate(voucher.createdDateTime) }}</v-col>
				<v-col v-if="voucher.createdDateTimeString == today" class="date-col">Today</v-col>
				<v-col v-if="voucher.createdDateTimeString == yesterday" class="date-col">Yesterday</v-col>
				<v-col class="amount-col">
					<span class="moneyIn">+{{ formatter.format(voucher.faceValue) }}</span>
				</v-col>
			</v-row>
		</div>

	</div>
	
</template>

<script>
export default {
	name: "AddButton",
	props: ['search', 'voucher', 'formatter', 'today', 'yesterday'],
	data() {
		return{
			query: "",
			hide: [],
			context: false
		}
	},
    computed: {
        formattedTransactionDate() {
            return (createdDateTime) => {
                const date = new Date(createdDateTime);
                if (isNaN(date)) {
                    return 'Invalid Date';
                }
                const day = String(date.getDate()).padStart(2, '0');
                const month = date.toLocaleString('default', { month: 'long' });
                const year = date.getFullYear();
                return `${day} ${month}, ${year}`;
            };
        }
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.icon {
	width: 35px;
	background-color: #E7ECF7;
	border-radius: 90px;
}

.amount-col {
	text-align: right;
	vertical-align: center;
	padding-top: 14px;
	padding-bottom: 0px;
}

.details-col {
	padding-top: 0px;
	padding-bottom: 0px;
	vertical-align: center;
}

.icon-col {
	padding-top: 2px;
	padding-bottom: 0px;
	vertical-align: center;
}

.context-col {
	text-align: right;
	padding-top: 0px;
	padding-bottom: 0px;
	vertical-align: center;
	img {
		opacity: 0.5;
	}
	transition: all .3s fade;
}

.context-button
{
	width: 40px !important;
	padding: 0px !important;
	min-width: 40px !important;
	height: 40px !important;
	margin-left: -1px;
}

.context-close-button
{
	width: 20px !important;
	padding: 0px !important;
	min-width: 20px !important;
	height: 40px !important;
	margin-left: -1px;
}

.date-col {
	font-size: 13px;
	padding-bottom: 10px;
}

.category {
	font-size: 11px;
	color: #555555;
	font-weight: lighter;
}

.moneyIn {
	background-color: #23af6433;
	color: #23AF64;
	padding: 3px;
	border-radius: 3px;
}

</style>