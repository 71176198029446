<template>
	<div v-if="!customer" class="middle-page-topthird">
		<v-progress-circular indeterminate></v-progress-circular>
	</div>
</template>

<script>
export default {
	name: "CustomerLoadWait",
	props: ['customer'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>


</style>