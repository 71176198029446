<template>
	<CustomerLoadWait :customer="customer && bankInfo" />
	<header-block v-if="customer && bankInfo" title="Accounts"></header-block>
	<div v-if="customer && bankInfo">

		<div class="balanceHeader">
			Total balance
			<p>{{ formatter.format(Math.round(accountsBalance)) }}</p>
		</div>

		<!-- Parent div to allow odd/even css selectors -->
		<div>
			<div :class="'hubItemWrapper'" v-for="a in accounts" :key="a.id">
				<AccountButton :circleIcon="true" :href="'#/account/' + a.id" :title="a.bank.prettyName ?? a.bank.name" :icon="a.bank.logoPath ? require('@/assets/logos/' + a.bank.logoPath) : require('@/assets/round1.png')" :provider="a.displayName" :balance="formatter.format(Math.round(a.balance))"></AccountButton>
			</div>
		</div>
			
		<div v-if="bankInfo" class="drawer" :style="'height: ' + drawerHeight + '; bottom: ' + drawerBottom + 'px;'" @click="toggleTransactions">
			<v-list-item style="text-align: center;">
				<div class="divider"></div>
				<p class="title">Recent Transactions</p>
			</v-list-item>
			<v-divider></v-divider>
			<TransactionsList v-if="bankInfo && bankInfo.recentTransactions" search="true" :transactions="bankInfo.recentTransactions" :formatter="formatter" :today="today" :yesterday="yesterday" />
		</div>

		<div style="clear:both; margin-bottom: 30px;"></div>

	</div>
	
</template>

<script>
import Services from '../mixins/Services';
import TransactionsList from './elements/TransactionsList.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import AccountButton from './elements/AccountButton.vue';
import HeaderBlock from './elements/HeaderBlock.vue';

export default {
	mixins: [Services],
	components: { TransactionsList, CustomerLoadWait, AccountButton, HeaderBlock },
	data() {
		return {
			section: 'd2d',
			drawerHeight: "82px",
			drawer:false,
			accounts: [],
			accountsBalance: 0,
		}
	},
	methods: {
		toggleTransactions: function() {
			this.drawer = !this.drawer;
			if (!this.drawer)
			{
				this.drawerHeight = "82px";
			}
			else
			{
				this.drawerHeight = "80%";
			}
		},
		getCustomer: function(){
			this._getCustomer(() => {
				
			});
		},
		getBankInfo: function(){
			this._getBankingInfo(() => {
				this.bankInfo.accounts.forEach((a) => 
				{
					if (a.accountType.prettyName == "Current" && this.$route.params.accountType.toLowerCase() == "current")
					{
						this.accounts.push(a);
						this.accountsBalance += a.balance;
					}
					else if (a.accountType.prettyName == "Credit" && this.$route.params.accountType.toLowerCase() == "credit")
					{
						this.accounts.push(a);
						this.accountsBalance += a.balance;
					}
					else if (a.accountType.prettyName == "Savings" && this.$route.params.accountType.toLowerCase() == "savings")
					{
						this.accounts.push(a);
						this.accountsBalance += a.balance;
					}
				});
			});
		},
		onLoad: async function() {
			this.getCustomer();
			this.getBankInfo();
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.title {
	font-size: 14px;
	font-weight: bold;
}

.divider {
	width: 48px;
	margin-bottom: 8px;
	margin-top: 8px;
	margin-right: auto;
	margin-left: auto;
	background: var(--Keyline, rgba(51, 40, 71, 0.10));
	height: 4px;
	border-radius: 4px;
}

.drawer {
	border-radius: 16px 16px 0px 0px;
	border: 0px;
	overflow-y: hidden;
	background-color: var(--background-color);
	position: fixed;
	width: 100%;
	left: 0px;
	transition: all .3s ease-out;
}

.topTabButton
{
	margin-top: 0px;
}

.hubItemWrapper {
	margin-top: 12px; 
	margin-bottom: 12px;
	margin-left: 0px; 
	width: 47%;
	float: left !important;
}

.hubItemWrapper:nth-child(even) {
	margin-right: 0px;
	float:right !important;
}

.topMenuCol {
	padding-left: 5px;
	padding-right: 5px;
}

.topMenuRow
{
	margin-left: -5px;
	margin-right: -5px;
	margin-bottom: 15px;
}

.balanceHeader {
	background: linear-gradient(202deg, #FCE5D4 49.85%, #E0C5C8 100%);
	height: 40vh;
	clear:both;
	margin-left: -30px;
	margin-right: -30px;
	margin-top: -138px;
	margin-bottom: -80px;
	color: var(--text-color);
	font-size: 15px;
	text-align: center;
	padding-top: 15vh;
	p{
		font-size: 48px;
		font-family: 'Gotham-Heavy';
		margin-top: 0px;
	};
}

</style>
