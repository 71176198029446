<template>
	<header-block title="Voucher" color="white" bgcolor="#281C3Bee" border="0"></header-block>
	<CustomerLoadWait :customer="payment" />

	<transition>
		<div v-if="availableVoucher">
			<div class="header-image" :style="'background-image: url(' + availableVoucher.cardUrl + ')'">
				<div class="header" >
				<v-row>
					<v-col class="v-col-9">
						<h1>{{ availableVoucher.merchant.name }}</h1>
						<p>{{ availableVoucher.description.substring(0, 200) }}...</p>
						<p class="percentage">{{ availableVoucher.discountPercentage }}% cashback</p>
						<v-chip v-if="availableVoucher.online" variant="outlined" background-color="blue">Online</v-chip> <v-chip v-if="availableVoucher.inStore" variant="outlined">In Store</v-chip>
					</v-col>
					<v-col class="v-col-3 logo-col">
						<img :src="availableVoucher.merchant.logoUrl">
					</v-col>
				</v-row>
				</div>
			</div>
		</div>
	</transition>
	<transition>
		<div class="cart" v-if="payment && meta && meta.type == 'voucher'">
			<h1>Payment Complete</h1>
			<p>Ordering your voucher . . .</p>
			<v-progress-circular class="mt-10" indeterminate></v-progress-circular>
		</div>
	</transition>

</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock},
	data() {
		return {
			payment: null,
			meta: null,
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
			this._getPayment(this.$route.query.paymentId, () => {
				this.meta = JSON.parse(this.payment.requestMeta);
				// TODO
				/*
				Treat meta as a shopping cart (rename it to cart?), this page should then
				fullfill each item in the cart.  in this case, the cart
				is going to be 1 or more vouchers. But in future
				could be other items.
				foreach (item in cart) {
					if (item.type == 'voucher') {
						// get the voucher
						// create the voucher
					}
				}
				*/
				if (this.meta) {
					this._getAvailableVoucher(this.meta.voucherId);
					this._orderVoucher(this.meta.slug, this.meta.amount, this.payment.id, () => {
						this.$router.replace({ path: "/myvouchers" });
					});
				}
			});
		},	
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>
	h1 {
		font-size: 24px;
	}
	.header-image {
		margin: -20px -30px 0px -30px;
		background-size: cover;
		margin-top: -138px;
		padding-top: 138px;
	}
	.header {
		margin-top: -138px;
		background-color: #281c3be7;
		padding: 30px;
		padding-top: 138px;
		color: white;
		padding-bottom: 200px;
	}
	.logo-col {
		text-align: right;
	}
	.logo-col img {
		width: 70px;
		border-radius: 40px;
	}
	.percentage {
		font-size: 16px;
		font-weight: bold;
	}
	p{
		font-size: 13px;
	}
	.cart {
		background-color: var(--background-color-alt);
		padding: 30px;
		margin-top: -150px;
		border-radius: 30px;
		padding-bottom: 40px;
		text-align: center;
		h2 {
			margin-bottom: 15px;
		}
		.constraints {
			margin-top: -10px;
			padding-top: 0px;
		}
		.small {
			font-size: 11px;
		}
		position: relative;
	}
</style>
