<template>
	<header-block title="Test page"></header-block>
	
	A test page
	
</template>

<script>
import Services from '../mixins/Services';
import HeaderBlock from './elements/HeaderBlock.vue';
//import AccountSummaryButton from './elements/AccountSummaryButton.vue';
//import TransactionsList from './elements/TransactionsList.vue';
// import CountBlock from './elements/CountBlock.vue';
// import HeaderBlock from './elements/HeaderBlock.vue';
// import InfoPanel from './elements/InfoPanel.vue';
// import ScrollThumbBlock from './elements/ScrollThumbBlock.vue';
// import AdButton from './elements/AdButton.vue';
//import CustomerLoadWait from './elements/CustomerLoadWait.vue';

export default {
	mixins: [Services],
	components: {HeaderBlock },
	data() {
		return {
			drawer: false,
			rail: true,
			currentAccounts: [],
			currentAccountsBalance: 0,
			creditAccounts: [],
			creditAccountsBalance: 0,
			savingsAccounts: [],
			savingsAccountsBalance: 0,
		}
	},
	watch: {
		// 'drawer': {
		// 	handler: function(newValue) {
		// 		if (!newValue)
		// 		{
		// 			this.drawer = true;
		// 			this.rail = true;
		// 		}
		// 	},
		// }
	},
	methods: {

		onLoad: async function () {

		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>
.title {
	font-size: 14px;
}

.divider {
	width: 48px;
	margin-bottom: 8px;
	margin-top: 8px;
	margin-right: auto;
	margin-left: auto;
	background: var(--Keyline, rgba(51, 40, 71, 0.10));
	height: 4px;
	border-radius: 4px;
}

.drawer {
	border-radius: 16px 16px 0px 0px;
	border: 0px;
	overflow-y: hidden;
}

.topTabButton {
	margin-top: 0px;
}
</style>
