<template>

	<v-row class="headerBlock" :style="'background-color: ' + (bgcolor ?? '#FBF7F4dd') + '; border-bottom: ' + (border ?? '1') + 'px solid var(--border-color);'">
		<v-col class="v-col-2 left-col">
			<!-- <v-button class="mg-circle-image-button"><img src="@/assets/info.png"/></v-button> -->
		</v-col>
		<v-col class="title" :style="'color: ' + (color ?? 'black')">{{title}}</v-col>
		<v-col class="v-col-2 right-col">
			<!-- <v-button class="mg-circle-image-button"><img src="@/assets/user.png"/></v-button> -->
		</v-col>
	</v-row>

</template>

<script>
export default {
	name: "HeaderBlock",
	props: ['title', 'color', 'bgcolor', 'border'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
			//alert(this.bgcolor);
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.headerBlock {
	height: 120px;
	padding-top: 67px;
	border-bottom: 1px solid var(--border-color);
	margin-bottom: 20px;
	margin-left: -30px;
	margin-right: -30px;

	position:sticky;
    top:0;
    width:100vw;
    z-index:100;
	/* background-color: var(--background-color-alt); */
}

.title {
	font-size: 15px;
	text-align: center;
}

.right-col {
	text-align: right;
}

.mg-circle-image-button img{
	height: 32px;
	width: 32px;
}

</style>