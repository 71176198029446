<template>
	<transition>
		<div v-if="show" style="margin-left: -30px; position:absolute; z-index:1000; width: 100%; height: 100vh; background-color: #281C3B; opacity: 0.8;" />
	</transition>
</template>

<script>
export default {
	name: "DarkenBackground",
	props: ['show'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>


</style>