<template>

	<v-row class="menuBar" :style="'height:' + bottomMenuHeight + 'px'">
		<v-col class="">
			<v-btn variant="flat" href="#/aiop">
				<img :src="require('@/assets/menu/Money' + (this.$route.meta.menuItem=='money' ? 'On' : '') + '.svg')"/>
			</v-btn>
		</v-col>
		<v-col class="">
			<v-btn variant="flat" href="#/guides">
				<img :src="require('@/assets/menu/Guide' + (this.$route.meta.menuItem=='guide' ? 'On' : '') + '.svg')"/>
			</v-btn>
		</v-col>
		<v-col class="">
			<v-btn variant="flat" href="#/">
				<img :src="require('@/assets/menu/Home' + (this.$route.meta.menuItem=='home' ? 'On' : '') + '.svg')"/>
			</v-btn>
		</v-col>
		<v-col class="">
			<v-btn variant="flat" href="#/completedmoves">
				<img :src="require('@//assets/menu/Activity' + (this.$route.meta.menuItem=='activity' ? 'On' : '') + '.svg')"/>
			</v-btn>
		</v-col>
		<v-col class="">
			<v-btn variant="flat" href="#/you">
				<img :src="require('@/assets/menu/You' + (this.$route.meta.menuItem=='you' ? 'On' : '') + '.svg')"/>
			</v-btn>
		</v-col>
	</v-row>

</template>

<script>
export default {
	name: "menuBar",
	props: [''],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.menuBar {
	height: 78px;
	position: fixed;
	bottom: 0px;
	left: 0px;
	width: 100%;
	margin-left: 0px;
	margin-right: 0px;
	background-color: var(--background-color);
	z-index: 2000;
	text-align: center;
	.v-col{
		padding: 0px;
		padding-top: 12px;
	}
}

</style>