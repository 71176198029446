<template>
	<CustomerLoadWait :customer="customer && voucher" />
	<header-block v-if="customer && voucher" bgcolor="#332847CC" :border="false" color="white" :title="voucher.discount.merchant.name"></header-block>
	<transition>
		<div class="topPanel" v-if="customer && voucher">
			<VoucherCard :voucher="voucher" :formatter="formatter" />
			<v-row class="button-row mt-5">
				<v-col class="button-col">
					<BarArrowButton :moreRounded="true" :bold="true" title="Redeem" @click="this.openDrawer();" />
				</v-col>
			</v-row>
		</div>
	</transition>
	<transition>
		<div class="transactionHistory" v-if="customer && voucher">
			<v-row justify="center">
				<v-col class="text-center">
					<h2>Transaction History</h2>
				</v-col>
			</v-row>
			<v-row class="mt-0">
				<v-col class="right">Balance: {{ formatter.format(voucher.currentBalance)}}</v-col>
			</v-row>
			<VoucherTransactionsList v-if="customer && voucher" search="false" :voucher="voucher" :formatter="formatter" :today="today" :yesterday="yesterday" />
			<v-row class="mt-4">
				<v-col>
					<b>Please note.</b> Retail Card balances are not updated automatically.  Keep track of your spending to make every penny count.
				</v-col>
			</v-row>
		</div>
	</transition>

	<DrawerPanel v-if="voucher" ref="drawer" openHeight="88%" closedHeight="0px" bottom="0px" backgroundColor="#FBF7F4" openBackgroundColor="white" title="Redeem Voucher">
		<v-row>
			<v-col v-if="voucher.currentBalance > 0">Keep track of your spending to make every penny count.</v-col>
		</v-row>
		<v-row v-if="voucher.currentBalance > 0">
			<v-col class="purchaseLabel"><b>Purchase total</b></v-col>
			<v-col class="right"><img class="cardLogo" :src="voucher.discount.cardUrl"/></v-col>
		</v-row>
		<v-row>
			<v-col class="purchaseTotal">
				<v-text-field v-if="voucher.currentBalance > 0"
						variant="solo"
						:flat="true"
						clearable
						single-line
						rounded
						outlined
						:rules="rules" 
						prefix="£" 
						v-model="purchaseTotal" 
						type="number" 
						label="Enter amount" />
			</v-col>
		</v-row>
		<v-row>
			<v-col v-if="voucher.currentBalance > 0" class="no-top-padding">
				Enter total to be spent using this retail card
			</v-col>
		</v-row>
		<v-row v-if="voucher.currentBalance > 0">
			<v-col>
				<span class="remaining">
					Remaining: <b>{{ formatter.format(Math.round(voucher.currentBalance - purchaseTotal)) }}</b>
				</span>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-btn v-if="voucher.currentBalance > 0" @click="addVoucherTransaction(purchaseTotal)" :flat="true" class="mt-7 mg-button">Redeem</v-btn>
				<v-btn v-if="!voucher.currentBalance > 0" @click="viewVoucher()" :flat="true" class="mt-7 mg-button">View</v-btn>
				<v-btn @click="closeDrawer()" :flat="true" class="mt-7 mg-button">Cancel</v-btn>
			</v-col>
		</v-row>
	</DrawerPanel>

</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import VoucherCard from './elements/VoucherCard.vue';
import BarArrowButton from './elements/BarArrowButton.vue';
import DrawerPanel from './elements/DrawerPanel.vue';
import VoucherTransactionsList from './elements/VoucherTransactionsList.vue';

export default {
	mixins: [Services],
	components: { CustomerLoadWait, HeaderBlock, VoucherCard, BarArrowButton, DrawerPanel, VoucherTransactionsList },
	data() {
		return {
			purchaseTotal: null,
			errorText: null,
			isDrawerOpen: false,
			rules: [
				value => !!value || 'Please enter an amount',
				value => (value && value <= this.voucher.currentBalance) || `Amount must be less than or equal to ${this.formatter.format(this.voucher.currentBalance)}`			],
		}
	},
	methods: {
		getCustomer: function(){
			this._getCustomer(() => {
				
			});
		},
		onLoad: async function() {
			this.getCustomer();
			this._getCustomerVoucher(this.$route.params.id, () =>
			{
			});
		},
		openDrawer: function() {
			this.isDrawerOpen = true;
			this.$refs.drawer.toggleDrawer();
		},
		closeDrawer: function() {
			this.isDrawerOpen = false;
			this.$refs.drawer.toggleDrawer();
		},
		addVoucherTransaction: function(amount) {
			if (this.purchaseTotal > this.voucher.currentBalance)
			{
				this.errorText = "Invalid purchase total";
				return;
			}
			this._addVoucherTransaction(this.voucher.id, amount, () => {
				window.location = this.voucher.url;
				this._getCustomerVoucher(this.$route.params.id);
				this.closeDrawer();
				this.purchaseTotal = 0;
			});
		},
		viewVoucher: function() {
			window.open(this.voucher.url, '_blank');
			this.closeDrawer();
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.title {
	font-size: 14px;
	font-weight: bold;
}
.purchaseLabel {
	padding-top: 17px !important;
}
.purchaseTotal {
	padding-top: 0px !important;
}
.info-header {
	background-color: #ffffff44;
	border-radius: 16px;
	margin:30px;
	padding: 10px;
	margin-top: 0px;
}

.button-row {
	padding:0px;
}
.button-col {
	padding-top:0px;
}

.balance-col {
	padding-left: 20px;
	font-size: 16px;
	text-align: left;
}

.balance-title {
	font-size: 12px !important;
	margin-bottom: 0px;
	font-weight: lighter;
}

.divider {
	width: 48px;
	margin-bottom: 8px;
	margin-top: 8px;
	margin-right: auto;
	margin-left: auto;
	background: var(--Keyline, rgba(51, 40, 71, 0.10));
	height: 4px;
	border-radius: 4px;
}

.drawer {
	border-radius: 16px 16px 0px 0px;
	border: 0px;
	overflow-y: hidden;
	background-color: var(--background-color);
	position: fixed;
	width: 100%;
	left: 0px;
	transition: all .3s ease-out;
}

.topTabButton
{
	margin-top: 0px;
}

.hubItemWrapper {
	margin-top: 12px; 
	margin-bottom: 12px;
	margin-left: 0px; 
	width: 47%;
	float: left !important;
}

.hubItemWrapper:nth-child(even) {
	margin-right: 0px;
	float:right !important;
}

.topMenuCol {
	padding-left: 5px;
	padding-right: 5px;
}

.topMenuRow
{
	margin-left: -5px;
	margin-right: -5px;
	margin-bottom: 15px;
}

.balanceHeader {
	background: linear-gradient(202deg, #FCE5D4 49.85%, #E0C5C8 100%);
	clear:both;
	margin-left: -30px;
	margin-right: -30px;
	margin-top: -138px;
	margin-bottom: -80px;
	color: var(--text-color);
	font-size: 15px;
	text-align: center;
	padding-top: 15vh;
	padding-bottom: 75px;
	p{
		font-size: 48px;
		font-family: 'Gotham-Heavy';
		margin-top: 0px;
	};
	.small {
		font-size: 10px;
		margin-top: -40px;
	}
}
.button-row {
	padding:0px;
}
.button-col {
	padding-top:0px;
}
.cardLogo
{
	width: 40px;
	border-radius: 4px;
}
.topPanel {
	padding: 20px; 
	padding-top: 140px; 
	margin-left: -30px; 
	margin-right: -30px; 
	margin-top: -138px;
	background: #413655;
}
.transactionHistory {
	padding-top: 30px;
}
.v-text-field ::v-deep(.v-field) {
	border: 1px solid #413655 !important;
	border-radius: 5px !important;
}
.remaining {
	background-color: #F9F7F3;
	padding: 9px;
	border-radius: 3px;
}

</style>

