<template>

	<div class="drawer" :style="'bottom: ' + bottom + '; background-color: ' + (drawer ? openBackgroundColor : backgroundColor) + '; height: ' + drawerHeight" @click="toggleDrawer">
		<v-list-item style="text-align: center;">
			<div class="divider"></div>
			<p class="title">{{ title }}</p>
		</v-list-item>
		<v-divider></v-divider>
		<div class="panelContent" @click="event => {event.stopPropagation()}">
			<slot />
		</div>
	</div>
	
</template>

<script>
export default {
	name: "DrawerPanel",
	props: ['title', 'backgroundColor', 'openBackgroundColor', 'bottom', 'openHeight', 'closedHeight'],
	data() {
		return{
			drawerHeight: this.closedHeight ?? "82px",
			drawer:false,
		}
	},
	methods: {
		toggleDrawer: function() {
			this.drawer = !this.drawer;
			if (!this.drawer)
			{
				this.drawerHeight = this.closedHeight ?? "82px";
				this.$emit("closed");
			}
			else
			{
				this.drawerHeight = this.openHeight ?? "80%";
				this.$emit("opened");
			}
		},
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

	.panelContent {
		padding: 20px;
	}

	.title{
		font-weight: bold;
	}

	.divider {
		width: 48px;
		margin-bottom: 8px;
		margin-top: 8px;
		margin-right: auto;
		margin-left: auto;
		background: var(--Keyline, rgba(51, 40, 71, 0.10));
		height: 4px;
		border-radius: 4px;
	}

	.drawer {
		border-radius: 16px 16px 0px 0px;
		border: 0px;
		overflow-y: hidden;
		position: fixed;
		width: 100%;
		left: 0px;
		transition: all .3s ease-out;
		z-index: 1000 !important;
	}

</style>