<template>
	<CustomerLoadWait :customer="customer && bankInfo" />
	<HeaderBlock title="All in one place" />
	<transition>
		<div v-if="customer && bankInfo" @scroll="alert()">

			<v-row class="topMenuRow">
				<v-col class="topMenuCol">
					<v-btn :class="'topTabButton ' + (section=='d2d' ? 'active' : '')" title="" @click="section='d2d'; drawerHeight = '79px'">Day-to-day</v-btn>
				</v-col>
				<v-col class="topMenuCol">
					<v-btn :class="'topTabButton ' + (section=='lt' ? 'active' : '')" title="" @click="section='lt'; drawerHeight = '0px'">Long term</v-btn>
				</v-col>
				<v-col class="topMenuCol">
					<v-btn :class="'topTabButton ' + (section=='ins' ? 'active' : '')" title="" @click="section='ins'; drawerHeight = '0px'">Insurances</v-btn>
				</v-col>
			</v-row>

			<div v-if="section == 'd2d'">
				<v-row>
					<v-col><AccountSummaryButton href="#/accounts/current" title="Current" :icon="require('@/assets/round1.png')" :count="currentAccounts.length" :total="formatter.format(currentAccountsBalance)"></AccountSummaryButton></v-col>
					<v-col><AccountSummaryButton href="#/accounts/credit" title="Credit" :icon="require('@/assets/round2.png')" :count="creditAccounts.length" :total="formatter.format(Math.round(creditAccountsBalance))"></AccountSummaryButton></v-col>
				</v-row>
				<v-row>
					<v-col><AccountSummaryButton href="#/accounts/savings" title="Savings" :icon="require('@/assets/round3.png')" :count="savingsAccounts.length" :total="formatter.format(Math.round(savingsAccountsBalance))"></AccountSummaryButton></v-col>
					<v-col><AccountSummaryButton v-if="vouchers" href="#/MyVouchers" title="Vouchers" :icon="require('@/assets/round1.png')" :count="vouchers.vouchers.length" :total="formatter.format(Math.round(vouchers.balance))" word="voucher"></AccountSummaryButton></v-col>
				</v-row>
			</div>

			<div v-if="section == 'lt'">
				<div :class="'hubItemWrapper'" v-for="m in mortgageInfo" :key="m.id">
					<AccountButton title="Mortgage" :icon="require('@/assets/round1.png')" :provider="m.account.provider.name" :balance="formatter.format(Math.round(m.account.balances[0].amount))"></AccountButton>
				</div>
				<div :class="'hubItemWrapper'" v-for="i in investmentInfo" :key="i.id">
					<AccountButton :title="i.accountType.name" :icon="require('@/assets/round1.png')" :provider="i.provider.name" :balance="formatter.format(Math.round(i.balance))"></AccountButton>
				</div>
			</div>

			<div v-if="section == 'ins'">
				<div :class="'hubItemWrapper'" v-for="i in insuranceInfo" :key="i.id">
					<InsuranceButton :title="i.policyType.name" :icon="require('@/assets/CarInsuranceIcon.svg')" :provider="i.provider.name" :expires="i.endDateString"></InsuranceButton>
				</div>
			</div>

			<ChatMgHoverButton />

			<DrawerPanel bottom="44px" title="Recent Transactions" backgroundColor="white" openBackgroundColor="white">
				<TransactionsList v-if="bankInfo && bankInfo.recentTransactions" search="true" :transactions="bankInfo.recentTransactions" :formatter="formatter" :today="today" :yesterday="yesterday" />
			</DrawerPanel>

		</div>
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import AccountSummaryButton from './elements/AccountSummaryButton.vue';
import TransactionsList from './elements/TransactionsList.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import InsuranceButton from './elements/InsuranceButton.vue';
import AccountButton from './elements/AccountButton.vue';
import ChatMgHoverButton from './elements/ChatMgHoverButton.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import DrawerPanel from './elements/DrawerPanel.vue';

export default {
	mixins: [Services],
	components: { TransactionsList, CustomerLoadWait, AccountSummaryButton, InsuranceButton, AccountButton, ChatMgHoverButton, HeaderBlock, DrawerPanel },
	data() {
		return {
			section: 'd2d',
			drawerHeight: "79px",
			drawer:false,
			currentAccounts: [],
			currentAccountsBalance: 0,
			creditAccounts: [],
			creditAccountsBalance: 0,
			savingsAccounts: [],
			savingsAccountsBalance: 0,
		}
	},
	methods: {
		toggleTransactions: function() {
			this.drawer = !this.drawer;
			if (!this.drawer)
			{
				this.drawerHeight = "79px";
			}
			else
			{
				this.drawerHeight = "80%";
			}
		},
		getCustomer: function(){
			this._getCustomer(() => {
				
			});
		},
		getBankInfo: function(){
			this._getBankingInfo(() => {
				this.bankInfo.accounts.forEach((a) => 
				{
					if (a.accountType.prettyName == "Current")
					{
						this.currentAccounts.push(a);
						this.currentAccountsBalance += a.balance;
					}
					else if (a.accountType.prettyName == "Credit")
					{
						this.creditAccounts.push(a);
						this.creditAccountsBalance += a.balance;
					}
					else if (a.accountType.prettyName == "Savings")
					{
						this.savingsAccounts.push(a);
						this.savingsAccountsBalance += a.balance;
					}
				});
			});
			this._getCustomerVouchers();
		},
		getInsurnaceInfo: function(){
			this._getInsuranceInfo(() => {
				
			});
		},
		getMortgageInfo: function(){
			this._getMortgageInfo(() => {
				
			});
		},
		getInvestmentInfo: function(){
			this._getInvestmentInfo(() => {
				
			});
		},
		onLoad: async function() {
			this.getCustomer();
			this.getBankInfo();
			this.getInsurnaceInfo();
			this.getMortgageInfo();
			this.getInvestmentInfo();
		}
	},
	mounted: function () {
		this.onLoad();
	}
}
</script>

<style scoped>

.title {
	font-size: 14px;
	font-weight: bold;
}

.divider {
	width: 48px;
	margin-bottom: 8px;
	margin-top: 8px;
	margin-right: auto;
	margin-left: auto;
	background: var(--Keyline, rgba(51, 40, 71, 0.10));
	height: 4px;
	border-radius: 4px;
}

.drawer {
	border-radius: 16px 16px 0px 0px;
	border: 0px;
	overflow-y: hidden;
	background-color: var(--background-color);
	position: fixed;
	width: 100%;
	left: 0px;
	transition: all .3s ease-out;
}

.topTabButton
{
	margin-top: 0px;
	text-transform: none;
	padding-top: 17px !important;
	padding-bottom: 30px !important;
	vertical-align: middle !important;
	font-weight: bold;
}

.hubItemWrapper {
	margin-top: 12px; 
	margin-bottom: 12px;
	margin-left: 0px; 
	width: 47%;
	float: left;
}

.hubItemWrapper:nth-child(even) {
	margin-right: 0px;
	float:right;
}

.topMenuCol {
	padding-left: 3px;
	padding-right: 3px;
}

.topMenuRow
{
	margin-left: -5px;
	margin-right: -5px;
	margin-bottom: 15px;
}

</style>
