<template>
	
	<div class="transactions-list" @click="event => {event.stopPropagation()}">
		<v-row v-if="search">
			<v-col class="search-col">
				<v-text-field
					v-model="query"
					prepend-inner-icon="mdi-magnify"
					density="compact"
					label="Search transactions"
					variant="solo"
					:flat="true"
					hide-details
					clearable
					single-line
					bg-color="#eeeeee"
				></v-text-field>
			</v-col>
		</v-row>
		<div class="transactions">
			<div v-for="g in searchedTransactions" :key="g">
				<v-row class="mt-2 mb-2">
					<v-col v-if="g[0].timestampString != today && g[0].timestampString != yesterday" class="date-col">{{g[0].timestampString}}</v-col>
					<v-col v-if="g[0].timestampString == today" class="date-col">Today</v-col>
					<v-col v-if="g[0].timestampString == yesterday" class="date-col">Yesterday</v-col>
				</v-row>
				<v-row class="mb-1" v-for="t in g" :key="t.id">
					<v-col class="v-col-2 icon-col" style="">
						<img v-if="t.effectiveClassification.iconPath" :src="require('@/assets/transactions/' + t.effectiveClassification.iconPath.toLowerCase())" class="icon">
						<img v-if="!t.effectiveClassification.iconPath" :src="require('@/assets/round3.png')" class="icon">
					</v-col>
					<v-col class="v-col-6 details-col">
						{{t.merchantName}}<br/>
						<span class="category">{{ t.effectiveClassification.mgClassification }}</span>
					</v-col>
					<v-col v-if="!context" class="amount-col">
						<v-if v-if="t.amount < 0">
							{{ formatter.format(t.amount * -1) }}
						</v-if>
						<v-if v-else>
							<span class="moneyIn">+{{ formatter.format(t.amount) }}</span>
						</v-if>
					</v-col>
					<v-col v-if="!context" class="v-col-1 context-col">
						<img :src="require('@/assets/menu/vertical-dots.svg')" @click="context = true"/>
					</v-col>
					<!-- <transition> -->
						<v-col v-if="context" class="v-col-4 context-col">
							<v-btn class="context-button" rounded="0" size="large" variant="outlined" @click="hide.push(t.merchantName.toLowerCase())" ><v-icon icon="mdi-eye-off-outline"/></v-btn>
							<!-- <v-btn class="context-button" rounded="0" size="large" variant="outlined" @click="context=false"><v-icon icon="mdi-close"/></v-btn> -->
							<v-btn class="context-close-button" rounded="0" size="large" variant="outlined" @click="context=false"><img :src="require('@/assets/menu/vertical-dots.svg')"/></v-btn>
						</v-col>
					<!-- </transition> -->
				</v-row>
				<v-divider class="mt-4"></v-divider>
			</div>
		</div>

	</div>
	
</template>

<script>
export default {
	name: "AddButton",
	props: ['search', 'transactions', 'formatter', 'today', 'yesterday'],
	data() {
		return{
			query: "",
			hide: [],
			context: false
		}
	},
	computed: {
		searchedTransactions() {
			var ret = [];
			if(this.query) {
				this.transactions.forEach(g => {
					var ts = [];
					g.forEach(t => {
						if (t.merchantName.toLowerCase().includes(this.query.toLowerCase())) {
							ts.push(t);
						}
					});
					if (ts.length > 0) {
						ret = [...ret, ts]
					}
				});
				return ret;
			} else {
				this.transactions.forEach(g => {
					var ts = [];
					g.forEach(t => {
						if (!this.hide.includes(t.merchantName.toLowerCase())) {
							ts.push(t);
						}
					});
					if (ts.length > 0) {
						ret = [...ret, ts]
					}
				});
				return ret;
			}
		},
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.transactions-list {
	padding: 20px;
	max-height: 90%;
	z-index: 1000;
	overflow-y: scroll;
}

.icon {
	width: 35px;
	background-color: #E7ECF7;
	border-radius: 90px;
}

.amount-col {
	text-align: right;
	vertical-align: center;
	padding-top: 0px;
	padding-bottom: 0px;
}

.details-col {
	padding-top: 0px;
	padding-bottom: 0px;
	vertical-align: center;
}

.icon-col {
	padding-top: 2px;
	padding-bottom: 0px;
	vertical-align: center;
}

.context-col {
	text-align: right;
	padding-top: 0px;
	padding-bottom: 0px;
	vertical-align: center;
	img {
		opacity: 0.5;
	}
	transition: all .3s fade;
}

.context-button
{
	width: 40px !important;
	padding: 0px !important;
	min-width: 40px !important;
	height: 40px !important;
	margin-left: -1px;
}

.context-close-button
{
	width: 20px !important;
	padding: 0px !important;
	min-width: 20px !important;
	height: 40px !important;
	margin-left: -1px;
}

.date-col {
	font-size: 13px;
	padding-bottom: 10px;
}

.category {
	font-size: 11px;
	color: #555555;
	font-weight: lighter;
}

.moneyIn {
	background-color: #23af6433;
	color: #23AF64;
	padding: 3px;
	border-radius: 3px;
}




</style>