<template>
	<img class="pageHeaderIcon"
		src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/640px-WhatsApp.svg.png" />
	<p v-if="!customer">Authenticating . . .</p>
	<div v-if="customer">
		<p>WhatsApp Coaching</p>
		<p>You can start a WhatsApp chat with one of our Money Coaches. Click the button below to begin.</p>
		<p><v-btn class="mg-button" :disabled="pressed" style="background-color: #1dcd45 !important;" @click="enableWhatsApp">Start Coaching
				Session</v-btn></p>
		<v-alert style="margin-top:50px" v-if="pressed" text="Thank you. You will shortly receive a WhatsApp message."
			type="success"></v-alert>
	</div>
</template>

<script>

import Services from '../mixins/Services'

export default {
	mixins: [Services],
	data() {
		return {
			pressed: false
		}
	},
	methods: {
		enableWhatsApp: function () {
			this.pressed = true;
			var request = {
				"title": "welcome_to_mg",
				"whatsApp": true,
				"whatsAppTemplate": true,
				"phoneNumber": this.customer.person.mobilePhone
			};
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Notifications", request)
				.then(() => {
				}
				)
		},
		getCustomer: function () {
			this._getCustomer();
		},
		onLoad: async function () {
			this.getCustomer();
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped></style>
