<template>
	<v-slide-group class="moves-widget">
		<MovePanel class="move-panel" v-for="move in moves" :key="move.id" :move="move.move" :customer-move="move" :show-description="showDescription" />
	</v-slide-group>
</template>

<script>
import MovePanel from './MovePanel.vue';

export default {
	name: "MovesWidget",
	props: ['moves', 'showDescription'],
	components: {MovePanel},
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.moves-widget {
	border-radius: 8px;
	padding: 18px;
	border-color: var(--border-color);
	border: 0px solid var(--border-color);
	height: 100%;
	margin-left: -17px;
	margin-right: -20px;
}

.title-col {
	font-size: 20px;
}

.arrow-col {
	text-align: right;
	padding-left: 0px;
	padding-top: 15px;
}

.arrow {
	width: 18px;
}

.move-panel {
	margin-right: 15px;
	float: left;
	max-width: 100vw;
	min-width: 75vw;
}


</style>