<template>

	<div class="mg-chat-hover">
		<v-btn class="imgButton" variant="text" @click="toggleDrawer">
			<img src="../../assets/ChatMgButton.png" />
		</v-btn>
	</div>

	<div class="drawer" :style="'height: ' + drawerHeight" @click="toggleDrawer">
		<v-list-item style="text-align: center;">
			<div class="divider"></div>
			<p class="title">Chat MG</p>
		</v-list-item>
		<v-divider></v-divider>
		<TransactionsList v-if="bankInfo && bankInfo.recentTransactions" search="true" :transactions="bankInfo.recentTransactions" :formatter="formatter" :today="today" :yesterday="yesterday" />
	</div>
	
</template>

<script>
export default {
	name: "ChatMgHoverButton",
	props: [],
	data() {
		return{
			drawer: false,
			drawerHeight: "0px",
		}
	},
	methods: {
		toggleDrawer: function() {
			this.drawer = !this.drawer;
			if (!this.drawer)
			{
				this.drawerHeight = "0px";
			}
			else
			{
				this.drawerHeight = "80%";
			}
		},
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

	.title{
		font-weight: bold;
	}

	.mg-chat-hover {
		position: fixed !important;
		bottom: 0 !important;
		right: 0 !important;
		z-index: 0 !important;
	}

	.divider {
		width: 48px;
		margin-bottom: 8px;
		margin-top: 8px;
		margin-right: auto;
		margin-left: auto;
		background: var(--Keyline, rgba(51, 40, 71, 0.10));
		height: 4px;
		border-radius: 4px;
	}

	.imgButton {
		position: fixed !important;
		bottom: 150px !important;
		right: 20px !important;
		z-index: 99999 !important;
		height: 80px !important;
		width: 80px !important;
		border-radius: 50% !important;
	}

	.imgButton img {
		height: 80px !important;
		width: 80px !important;
		border-radius: 50% !important;
	}

	.drawer {
		border-radius: 16px 16px 0px 0px;
		border: 0px;
		overflow-y: hidden;
		background-color: var(--background-color);
		position: fixed;
		bottom: -20px;
		width: 100%;
		left: 0px;
		transition: all .3s ease-out;
		z-index: 1000 !important;
	}

</style>