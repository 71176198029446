<template>
	<CustomerLoadWait :customer="customer" />
	<HeaderBlock title="You" />
	<transition>
		<div v-if="customer">
			<v-row>
				<v-col>
					<CountBlock :href="!cripple ? '/#/CompletedMoves' : null" title="Moves completed" :value="customer.facts['M_COMPLETED'] ?? '0'"></CountBlock>
				</v-col>
				<v-col>
					<CountBlock href="MG_POP.html?view=appscreen-Home-Resources-Guide" title="Guides you've read" :value="customer.facts['CMS_READ'] ?? '0'" :subvalue="'of ' + customer.facts['CMS_UNREAD']"></CountBlock>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<CountBlock :href="!cripple ? '/#/aiop' : null" title="Accounts" :value="customer.facts['B_ACCOUNTS'] ?? '0'"></CountBlock>
				</v-col>
				<v-col>
					<CountBlock href="MG_POP.html?view=appscreen-Home-Spending" title="Transactions tagged" :value="customer.facts['B_TAGGEDTRANSACTIONS'] ?? '0'"></CountBlock>
				</v-col>		
			</v-row>
			<v-row v-if="!customer.person.whatsAppEnabled">
				<v-col>
					<AdButton title="Whatsapp" href="/#/EnableWhatsapp" subtitle="Enable Whatsapp to chat with us about your finances &amp; send in your documents for automatic processing!" 
					:topicon="require('@/assets/Whatsapp.webp')" :bottomrighticon="require('@/assets/go.png')" />
				</v-col>
			</v-row>
			<v-row v-if="showComingSoon && true == false">
				<v-col>
					<InfoPanel :blur="true" :icon="require('@/assets/coins.png')" title="Cashback" subtitle="Money back on spending" valueTitle="May 2024" value="£32.50" />
				</v-col>
			</v-row>
			<v-row v-if="this.customer && this.customer.person.addresses[0]">
				<v-col>
					<AdButton title="Switch" :href="'https://moneyguided.aptap.co.uk?p1=' + this.customer.person.email + '&p2=' + this.customer.person.addresses[0].postCode" subtitle="Move to a better deal" 
					:topicon="require('@/assets/switch.png')" :bottomrighticon="require('@/assets/go.png')" />
				</v-col>
				<v-col>
					<AdButton href="MG_POP.html?view=appscreen-Home-Resources-Marketplace" title="Marketplace" subtitle="Financial services" 
					:topicon="require('@/assets/switch.png')" :bottomrighticon="require('@/assets/family.png')" />
				</v-col>
			</v-row>
			<v-row v-if="showComingSoon">
				<v-col>
					<ScrollThumbBlock :blur="true" title="Recommended Vouchers" subtitle="Based on your spending habits" :thumbs="thumbs" :rightimage="require('@/assets/arrow-right.png')"/>
				</v-col>
			</v-row>
			
			<v-row>
				<v-col>
					<ScrollThumbBlock href="MG_POP.html?view=appscreen-Home-Resources-Benefits" title="Employee benefits" :thumbs="benefits" :rightimage="require('@/assets/arrow-right.png')"/>
				</v-col>
			</v-row>

			<!-- <v-row>
				<v-col><PayRiseCalculator :api="api"/></v-col>
			</v-row> -->
		</div>
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import CountBlock from './elements/CountBlock.vue';
import InfoPanel from './elements/InfoPanel.vue';
import ScrollThumbBlock from './elements/ScrollThumbBlock.vue';
import AdButton from './elements/AdButton.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';

export default {
	mixins: [Services],
	components: {HeaderBlock, CountBlock, InfoPanel, ScrollThumbBlock, AdButton, CustomerLoadWait},
	data() {
		return {
			cripple: true,
			showComingSoon: true,
			whatsappPressed: false,
			thumbs: [
				{img: require('@/assets/prod1.png')}, 
				{img: require('@/assets/prod2.png')}, 
				{img: require('@/assets/prod3.png')}, 
				{img: require('@/assets/prod4.png')}, 
				{img: require('@/assets/prod1.png')}, 
				{img: require('@/assets/prod2.png')}, 
				{img: require('@/assets/prod3.png')}]
		}
	},
	methods: {
		getCustomer: function(){
			this._getCustomer(() => {
				//this._getFeatures();
				this._getProducts(this.customer.employerID);
			});
		},
		onLoad: async function() {
			this._getCustomerVouchers();
			this.getCustomer();
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

</style>
