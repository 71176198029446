<template>
	<div :class="'bar-arrow-button ' + (moreRounded ? 'moreRounded' : '') ">
		<a :href="href">
			<v-row>
				<v-col v-if="icon && icon != ''" class="icon-col v-col-3">
					<img :src="icon"/>
				</v-col>
				<v-col class="title-col">
					<div :class="'title ' + bold ? 'bold' : ''" :style="!icon ? 'padding-left:30px; text-align:center' : ''">{{ title }}</div>
				</v-col>
				<v-col class="arrow-col v-col-2">
					<img class="arrow" src="../../assets/arrow-right.png">
				</v-col>
			</v-row>
		</a>
	</div>
</template>

<script>
export default {
	name: "BarArrowButton",
	props: ['icon','title', 'href','bold', 'disabled', 'moreRounded'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.bar-arrow-button {
	border-radius: 8px;
	padding: 6px;
	border-color: var(--border-color);
	border: 1px solid var(--border-color);
	background-color: var(--background-color);
}

.moreRounded {
	border-radius: 30px;
}

.title-col {
	padding: 0px;
	padding-top: 18px;
}

.title {
	font-size: 12px;
}

.icon-col {
	padding: 0px;
	padding-top: 13px;
	text-align: left;
	padding-left: 15px;
}

.icon-col img {
	height: 30px;
}

.value-title {
	font-size: 10px;
}

.value {
	font-size: 16px;
}

.right-col {
	text-align: center;
	border-left: 1px solid var(--border-color);
}

.comingSoon {
	text-align: center;
	padding-top: 6px;
	padding-bottom: 6px;
	margin-bottom: -18px;
	margin-top: 16px;
	border-top: 1px solid var(--border-color);
	margin-right: -18px;
	margin-left: -18px;
	background-color: #FEF9F4;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	font-size: 10px;
	background: linear-gradient(180deg, #FEF9F4 0%, #F7F1F1 100%);
}

.arrow-col {
	text-align: right;
	padding-top: 18px;
	padding-right: 18px;
}

.arrow {
	height: 20px;
}

</style>