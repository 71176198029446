<template>
	
	<div class="voucher-list" @click="event => {event.stopPropagation()}">
		<v-row v-if="search" class="mb-4">
			<v-col class="search-col">
				<v-text-field
					v-model="query"
					prepend-inner-icon="mdi-magnify"
					density="compact"
					label="Search vouchers"
					variant="solo"
					:flat="true"
					hide-details
					clearable
					single-line
					bg-color="#eeeeee"
					@click="query=''"
					@blur="if (query == '') { query = null; }"
				></v-text-field>
			</v-col>
		</v-row>
		<transition>
			<div class="voucher-list" v-if="searchedVouchers">
				<div v-for="v in searchedVouchers" :key="v.id">
					<a :href="'/#/vouchers/' + v.id">
						<v-row class="mb-1">
							<v-col class="v-col-3 icon-col" style="">
								<img :src="v.cardUrl" class="icon">
							</v-col>
							<v-col class="v-col-6 details-col">
								<span class="bold">{{v.merchant.name}}</span><br/>
								<span class="description">{{ v.description }}</span>
								<div class="mt-1">
									<v-chip v-if="v.online" variant="outlined" size="x-small" background-color="blue">Online</v-chip> <v-chip size="x-small" v-if="v.inStore" variant="outlined">In Store</v-chip>
								</div>
							</v-col>
							<v-col v-if="!context" class="v-col-3 amount-col">
								Save<br/>
								<span class="percentage">{{ v.discountPercentage }}%</span><br/>
								<img src="../../assets/DiscountUnderline.png" />
							</v-col>
						</v-row>
						<v-divider class="mt-2 mb-6"></v-divider>
					</a>
				</div>
			</div>
		</transition>
	</div>
	
</template>

<script>
export default {
	name: "AddButton",
	props: ['search', 'preSearch', 'categories', 'vouchers', 'formatter', 'today', 'yesterday', 'showAll'],
	data() {
		return{
			query: this.preSearch ?? null,
			context: false
		}
	},
	computed: {
		searchedVouchers() {
			//alert(this.query + ' ' + this.categories + ' ' + this.showAll);
			var ret = [];
			if (this.query == null && !this.categories) {
				ret = this.showAll ? this.vouchers : null;
				this.$emit('onFilterApplied', ret ? ret.length : 0);
				return ret;
			}
			if (this.query == "" || this.query == null) {
				ret = this.vouchers;
			} else {
				ret = this.vouchers.filter(v => v.merchant.name.toLowerCase().includes(this.query.toLowerCase()) || v.description.toLowerCase().includes(this.query.toLowerCase()));
			}
			if (this.categories) {
				var toSearch = ret;
				ret = [];
				var cats = this.categories.split(",");
				cats.forEach(cat => {
					var found = toSearch.filter(v => v.merchant.categories.toLowerCase().includes(cat.toLowerCase()));
					found.forEach(f => {
						if (!ret.includes(f)) ret = [...ret, f];
					});
				});
			}
			this.$emit('onFilterApplied', ret.length);
			return ret;
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.voucher-list {

}

.icon {
	width: 65px;
	background-color: #E7ECF7;
	border-radius: 10px;
}

.amount-col {
	text-align: center;
	vertical-align: center;
	img {
		width: 25px;
	}
}

.details-col {

	vertical-align: center;
	text-overflow: ellipsis;
	font-size: 14px;
}

.icon-col {
	vertical-align: middle;
}

.description {
	font-size: 12px;
	color: #555555;
	font-weight: lighter;
	display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.percentage {
	font-size: 18px;
	font-weight: bold;
}

/* Come back to this */
.search-col .v-text-field .v-field__clearable {
	display: block !important;
}

</style>