<template>
  <div class="centered">
    <img class="pageHeaderIconBigger" src="@/assets/LinkIcon.svg"/>
  </div>
  <div class="test-page">
      <h2>Completing Account Link</h2>
      <p v-if="!done">We are completing your connection now. Please wait . . .</p>
      <p v-if="done">All done! taking you back to your Finances . . .</p>
  </div>
</template>

<script>
import Services from '../mixins/Services';
export default {
  mixins:[Services],
  data() {
    return {
      code: null,
      done: false
    }
  },
  methods: {  
    completeLink: function(){
      this.$http.get(this.api + "/moneyhub?code=" +this.$route.params["code"])
        .then(() => {
          this.done = true;
          window.location.href = "/MG_POP.html";
        }
      )
    },
    onLoad: async function() {
      this.completeLink();
    }
  },
  mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

h2 {
  font-size: 15px;
}
p {
  font-size: 12px;
}

</style>
