<template>
	<div class="accounts-widget">
		<v-row>
			<v-col class="v-col-7 title-col">
				My Accounts
			</v-col>
			<v-col class="see-all-col">
				<a href="#/aiop">See All</a>
			</v-col>
		</v-row>
		<div class="restricted-height">
			<v-row v-for="a in accounts" :key="a.id" class="mt-5" @click="$router.push('account/' + a.id)">
				<v-col class="v-col-2 icon-col">
					<img class="icon" :src="a.bank.logoPath ? (require('@/assets/logos/' + a.bank.logoPath)) : require('@/assets/round1.png')" />
				</v-col>
				<v-col class="account-name-col">
					{{ a.bank.prettyName ?? a.bank.name }}<br/>
					<span>{{ a.accountType.prettyName }}</span>
				</v-col>
				<v-col class="account-balance-col">
					{{ formatter.format(Math.round(a.balance)) }}
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>

export default {
	name: "AccountsWidget",
	components: { },
	props: ['accounts', 'formatter'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.accounts-widget {
	border-radius: 8px;
	padding: 18px;
	padding-bottom: 30px;
	border-color: var(--border-color);
	border: 0px solid var(--border-color);
	height: 100%;
	background-color: var(--background-color);
	width: 100%;
}

.r--estricted-height {
	max-height: 97px;
	overflow-y: scroll;
	overflow-x: hidden;
}

.title-col {
	font-size: 20px;
}

.see-all-col {
	text-align: right;
	padding-left: 0px;
	padding-top: 15px;
}

.arrow {
	width: 18px;
}

.icon-col {
	padding-top: 2px;
	padding-bottom: 0px;
}

.see-all-col {
	padding-top: 20px;
	a {
		text-decoration: underline;
	}
}

.icon {
	width: 30px;
	background: linear-gradient(230deg, #E7ECF7 16.24%, #EAEDFF 84.93%);
	border-radius: 50%;
}

.account-name-col {
	font-size: 12px;
	padding-top: 0px;
	padding-bottom: 0px;
	span {
		font-size: 10px;
	}
}

.account-balance-col {
	font-size: 12px;
	text-align: right;
	padding-top: 8px;
	padding-bottom: 0px;
}


</style>