<template>
	<header-block title="Guide to your finances"></header-block>
	<CustomerLoadWait :customer="plans" />
	<transition>
		<div v-if="plans">
			<v-row v-for="p in plans" :key="p.id">
				<v-col>
					<GuideButton :guide="p" />
				</v-col>
			</v-row>
		</div>
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import GuideButton from './elements/GuideButton.vue';
import HeaderBlock from './elements/HeaderBlock.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, GuideButton},
	data() {
		return {
			
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
			this._getPlans();
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

</style>
