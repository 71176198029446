<template>
	<CustomerLoadWait :customer="article" />
	<div v-if="article" class="article">
		<HeaderBlock :title="'Guide'" />
		<img :src="article.image" />
		<h1>{{ article.title }}</h1>
		<p><v-chip variant="outlined" size="small">{{ article.tagsArray[0] }}</v-chip></p>
		<p v-html="article.body.replaceAll('</h2>\n\n', '</h2>\n').replaceAll('\n', '<br/>')" />
	</div>	
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock},
	data() {
		return {
			
		}
	},
	methods: {
		onLoad: async function() {
			this._getCmsArticle(this.$route.params.id);
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.article {
	img {
		width: 100%;
		/* width: 100vw;
		margin-left: -30px;
		margin-right: -30px;
		margin-top: -30px; */
		margin-bottom: 15px;
	}
	h1 {
		margin-top: 8px;
		margin-bottom: 15px;
	}
	.v-chip {
		margin-bottom: 15px;
	}
	p {
		margin-top: 15px;
		margin-bottom: 15px;
	}
}


</style>
