<template>
	<v-row class="bullet-row"><v-col cols="1"><img :src="bulletImage"></v-col><v-col class="caption">{{caption}}</v-col></v-row>
</template>

<script>
export default {
	name: "BulletItem",
	props: ['bulletImage', 'caption'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>
	.bullet-row {
		padding-bottom: 0px !important;
	}
	.bullet-row img {
		height: 30px;
	}
	.caption {
		font-size: 15px; margin-left: 15px; padding-top: 15px;
	}
</style>