<template>
	<header-block :title="title" :border="image ? 0 : null" :bgcolor="image ? '#00000000' : 'white'" :color="image ? 'white' : null"></header-block>
	<CustomerLoadWait :customer="availableVouchers && vouchers" />

	<transition>
		<div class="head" v-if="filter && availableVouchers">
			<div class="header" :style="'background-image: url(' + require('@/assets/categories/blurred/' + image) + ')'">
				<v-row>
					<v-col class="v-col-7 title-col">
						<h1>{{ title }}</h1>
					</v-col>
					<v-col class="v-col-5 chip-col">
						<v-chip color="white" variant="flat">{{ filterCount }} card(s)</v-chip>
					</v-col>
				</v-row>
			</div>
		</div>
	</transition>

	<transition>
		<div v-if="availableVouchers">
			<VoucherList @onFilterApplied="(count) => { filterCount = count }" :categories="filter" :showAll="false" :vouchers="availableVouchers" :search="!filter" />
		</div>
	</transition>

	<transition>
		<div v-if="!filter">
			<v-row>
				<v-col><CategoryButton @click="navFilter({ title:'Bakery & Coffee', filters:'food-and-drink', image:'bakery-coffee.png' });"  :image="require('@/assets/categories/bakery-coffee.png')" title="Bakery & coffee" /></v-col>
				<v-col><CategoryButton @click="navFilter({ title:'Entertainment', filters :'gaming,music,tv-and-movies', image:'entertainment.png' });" :image="require('@/assets/categories/entertainment.png')" title="Entertainment" /></v-col>
			</v-row>
			<v-row>
				<v-col><CategoryButton @click="navFilter({ title:'Family', filters :'baby,craft,toys', image:'family.png' });" :image="require('@/assets/categories/family.png')" title="Family" /></v-col>
				<v-col><CategoryButton @click="navFilter({ title:'Fashion', filters :'fashion', image:'fashion.png' });" :image="require('@/assets/categories/fashion.png')" title="Fashion" /></v-col>
			</v-row>
			<v-row>
				<v-col><CategoryButton @click="navFilter({ title:'Gifts', filters :'jewellery,fashion,beauty,music', image:'gifts.png' });" :image="require('@/assets/categories/gifts.png')" title="Gifts" /></v-col>
				<v-col><CategoryButton @click="navFilter({ title:'Home & Garden', filters :'supermarket,home,garden,diy', image:'home-garden.png' });" :image="require('@/assets/categories/home-garden.png')" title="Home & Garden" /></v-col>
			</v-row>
			<v-row>
				<v-col><CategoryButton @click="navFilter({ title:'Hotels & Travel', filters :'travel-and-leisure', image:'hotels.png' });" :image="require('@/assets/categories/hotels.png')" title="Hotels" /></v-col>
				<v-col><CategoryButton @click="navFilter({ title:'Leisure', filters :'travel-and-leisure,sports,gaming,music,craft', image:'leisure.png' });" :image="require('@/assets/categories/leisure.png')" title="Leisure" /></v-col>
			</v-row>
		</div>
	</transition>
	
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import VoucherList from './elements/VoucherList.vue';
//import CustomerVoucherList from './elements/CustomerVoucherList.vue';
import CategoryButton from './elements/CategoryButton.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, VoucherList, /* CustomerVoucherList ,*/ CategoryButton},
	data() {
		return {
			filter: this.$route.query.filters ?? null,
			filterCount: 0,
			title: this.$route.query.title ?? "Browse all retail cards",
			image: this.$route.query.image ?? null,
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
			this._getAvailableVouchers();
			this._getCustomerVouchers();
		},
		navFilter: function(o) {
			this.$router.push({ path: '/vouchers', query: o });
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.header {
	padding-top: 138px;
	background-size: cover;
	background-position: bottom;
	margin: -20px -30px 0px -30px;
	margin-top: -138px;
	color: white;
	p--adding-bottom: 200px;
	height: 300px;
	padding: 30px;
	padding-top: 200px;
	color: white;
	h1{
		font-size: 28px;
		margin-bottom: 15px;
	}
	margin-bottom: 40px;
}
.title-col {
	padding-top: 0px;
	padding-bottom: 20px;
}
.chip-col {
	text-align: right;
}
</style>
