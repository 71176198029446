<template>
	<a :href="href != null ? href : $router.path">
		<div class="analysis-widget">
			<v-row>
				<v-col class="v-col-7 title-col">
					Analysis
				</v-col>
				<v-col class="arrow-col">
					<img class="arrow" src="../../assets/arrow-right.png">
				</v-col>
			</v-row>
			<v-row>
				<v-col class="centered" v-if="!analysis">
					<v-progress-circular  indeterminate></v-progress-circular>
				</v-col>
			</v-row>
			<div v-if="analysis">
				<v-row>
					<v-col class="centered">In: {{ formatter.format(Math.round(analysis.cycledAnalysis[0].totalIn)).replace('-', '') }}</v-col>
					<v-col class="centered">Out: {{ formatter.format(Math.round(analysis.cycledAnalysis[0].totalOut)).replace('-', '') }}</v-col>
					<v-col class="centered">Pay in: {{ analysis.daysTillCycleResets }}</v-col>
				</v-row>
			</div>
		</div>
	</a>
</template>

<script>

export default {
	name: "AnalysisWidget",
	components: {},
	props: ['analysis', 'formatter'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.analysis-widget {
	border-radius: 8px;
	padding: 18px;
	border-color: var(--border-color);
	border: 0px solid var(--border-color);
	height: 100%;
	background-color: var(--background-color);
	width: 100%;
}

.title-col {
	font-size: 20px;
}

.arrow-col {
	text-align: right;
	padding-left: 0px;
	padding-top: 20px;
}

.arrow {
	width: 18px;
}


</style>