<template>
	<header-block :title="'Good ' + timeOfDay"></header-block>
	<transition>

		<div class="login">
			<h2>Login</h2>
			<v-text-field
				v-model="email"
				type="email"
				p--repend-inner-icon="mdi-gbp"
				d--ensity="compact"
				label="Email address"
				variant="solo"
				:flat="true"
				h--ide-details
				clearable
				single-line
				bg-color="white"
			/>
			<v-text-field
				v-model="password"
				type="password"
				p--repend-inner-icon="mdi-gbp"
				d--ensity="compact"
				label="Password"
				variant="solo"
				:flat="true"
				h--ide-details
				clearable
				single-line
				bg-color="white"/>
			<v-btn @click="login()" class="mt-7 mg-button">Login</v-btn>
			
		</div>
		
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import HeaderBlock from './elements/HeaderBlock.vue';

export default {
	mixins: [Services],
	components: {HeaderBlock},
	data() {
		return {
			email: "",
			password: ""
		}
	},
	computed: {
		
	},
	methods: {
		onLoad: async function() {
			
		},
		login() {
			this._login(this.email, this.password, () => {
				this.$router.replace({path: "/MyVouchers" });
			});
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.login {
	background-color: var(--background-color-alt);
	padding: 30px;
	margin-top: 150px;
	border-radius: 30px;
	padding-bottom: 40px;
	h2 {
		margin-bottom: 15px;
		font-weight: normal;
	}
	.constraints {
		margin-top: -10px;
		padding-top: 0px;
	}
	.small {
		font-size: 11px;
	}
	position: relative;
}

</style>
