<template>
	<HeaderBlock v-if="customer && bankAccount" :title="bankAccount.bank.prettyName ? bankAccount.bank.prettyName : bankAccount.bank.name" :bgcolor="'#442066'" :color="'white'" :border="false" />
	<CustomerLoadWait :customer="customer && bankAccount" />
	<div v-if="customer && bankAccount">
		<div class="topPanel" style="">
			<BankCard :account="bankAccount" :formatter="formatter" />
		</div>
		<div class="transactionsWrapper" style="">
			
			<v-list-item style="text-align: center;">
				<div class="divider"></div>
				<p class="title">Transactions</p>
			</v-list-item>
			<v-divider></v-divider>
			<TransactionsList :transactions="bankAccount.groupedTransactions" :formatter="formatter" :search="true"/>

		</div>
	</div>
	
</template>

<script>
import Services from '../mixins/Services';
import BankCard from './elements/BankCard.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import TransactionsList from './elements/TransactionsList.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, BankCard, TransactionsList, HeaderBlock},
	data() {
		return {
			section: 'd2d',
			drawerHeight: "82px",
			drawer:false,
			accounts: [],
			accountsBalance: 0,
		}
	},
	methods: {
		toggleTransactions: function() {
			this.drawer = !this.drawer;
			if (!this.drawer)
			{
				this.drawerHeight = "82px";
			}
			else
			{
				this.drawerHeight = "80%";
			}
		},
		getCustomer: function(){
			this._getCustomer(() => {
				
			});
		},
		getBankAccount: function(id){
			this._getBankAccount(id, () => {
			});
		},
		getBankInfo: function(){
			this._getBankingInfo(() => {
				this.bankInfo.accounts.forEach((a) => 
				{
					if (a.accountType.prettyName == "Current" && this.$route.params.accountType.toLowerCase() == "current")
					{
						this.accounts.push(a);
						this.accountsBalance += a.balance;
					}
					else if (a.accountType.prettyName == "Credit" && this.$route.params.accountType.toLowerCase() == "credit")
					{
						this.accounts.push(a);
						this.accountsBalance += a.balance;
					}
					else if (a.accountType.prettyName == "Savings" && this.$route.params.accountType.toLowerCase() == "savings")
					{
						this.accounts.push(a);
						this.accountsBalance += a.balance;
					}
				});
			});
		},
		onLoad: async function() {
			this.getCustomer();
			this.getBankAccount(this.$route.params.id);
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.title {
	font-size: 14px;
}

.divider {
	width: 48px;
	margin-bottom: 8px;
	margin-top: 8px;
	margin-right: auto;
	margin-left: auto;
	background: var(--Keyline, rgba(51, 40, 71, 0.10));
	height: 4px;
	border-radius: 4px;
}

.drawer {
	border-radius: 16px 16px 0px 0px;
	border: 0px;
	overflow-y: hidden;
	background-color: var(--background-color);
	position: fixed;
	bottom: -20px;
	width: 100%;
	left: 0px;
	transition: all .3s ease-out;
}

.topTabButton
{
	margin-top: 0px;
}

.hubItemWrapper {
	margin-top: 12px; 
	margin-bottom: 12px;
	margin-left: 0px; 
	width: 47%;
	float: left;
}

.hubItemWrapper:nth-child(even) {
	margin-right: 0px;
	float:right;
}

.topMenuCol {
	padding-left: 5px;
	padding-right: 5px;
}

.topMenuRow
{
	margin-left: -5px;
	margin-right: -5px;
	margin-bottom: 15px;
}

.topPanel {
	padding: 20px; 
	padding-bottom: 36px; 
	padding-top: 140px; 
	margin-left: -30px; 
	margin-right: -30px; 
	margin-top: -138px;
	background: linear-gradient(202deg, #442066 49.85%, #42397b 100%);
}

.transactionsWrapper {
	border-radius: 16px 16px 0px 0px; 
	margin-top: -16px; 
	margin-left: -30px; 
	margin-right: -30px; 
	background-color: var(--background-color);
	margin-bottom: -35px;
	min-height: 50vh;
}

</style>
