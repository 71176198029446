<template>
	<a :href="href != null ? href : $router.path">
		<div class="guide-guide-button" @click="$router.push('Guides/' + guide.id)">
			<v-row>
				<v-col>
					<img class="icon" :src="guide.icon ?? require('@/assets/round1.png')" />
				</v-col>
			</v-row>
			<v-row class="mt-0">
				<v-col class="title-col">
					{{ guide.title }}
				</v-col>
			</v-row>
			<v-row class="mt-0">
				<v-col class="v-col-1 dot-col">
					<img :src="require('@/assets/dot.svg')">
				</v-col>
				<v-col class="v-col-7 tasks-col">
					0 / {{ guide.sections.length }} tasks
				</v-col>
				<v-col class="arrow-col">
					<img class="arrow" src="../../assets/arrow-right.png">
				</v-col>
			</v-row>
		</div>
	</a>
</template>

<script>
export default {
	name: "AddButton",
	props: ['guide'],
	data() {
		return {

		}
	},
	methods: {
		onLoad() {
			//alert(this.guide.sections && this.guide.sections.tasks ? this.guide.sections.tasks.length : 0);
		}
	},
	mounted: function () { this.onLoad(); }
}
</script>

<style scoped>
.guide-guide-button {
	border-radius: 8px;
	padding: 18px;
	border-color: var(--border-color);
	border: 0px solid var(--border-color);
	height: 100%;
	background-color: var(--background-color);
	width: 100%;
}

.title-col {
	font-size: 20px;
}

.icon {
	width: 40px;
	background: linear-gradient(230deg, #E7ECF7 16.24%, #EAEDFF 84.93%);
	border-radius: 50%;
}

.arrow-col {
	text-align: right;
	padding-left: 0px;
}

.arrow {
	width: 18px;
}

.tasks-col {
	padding-right: 0px;
	vertical-align: middle !important;
	padding-left: 5px;
}

.dot-col {
	vertical-align: middle !important;
	padding-top: 13px;
}
</style>