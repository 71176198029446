<template>
	<div id="checkout">
	</div>
</template>

<script>

export default {
	name: "StripeCheckout",
	props: ['secret', 'stripeKey'],
	data() {
		return{
			stripeScript: null,
		}
	},
	methods: {
		onLoad() {
			this.stripeScript = document.createElement('script');
			//alert(this.stripeKey);
			// new variable for Vue elements.
			let self = this;
			self.stripeScript.onload = async () => {
				//alert(self.stripeKey);
				const stripe = window.Stripe(self.stripeKey);
				// call a script function using 'window' scope.
				const checkout = await stripe.initEmbeddedCheckout({ clientSecret: self.secret });
				checkout.mount('#checkout');
			};
			this.stripeScript.setAttribute('src', 'https://js.stripe.com/v3/');
			document.head.appendChild(this.stripeScript);
		}
	},
	mounted: function() { this.onLoad(); },
	beforeUnmount() {
		window.Stripe = undefined;
		document.head.removeChild(this.stripeScript);
	}
}

</script>

<style scoped>
#checkout {
	margin: 0px;
	margin-left: -15px;
	margin-right: -15px;
	background-color: transparent;
}
</style>